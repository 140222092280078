export enum ActivationStatuses {
  ACTIVATE_SIM = 'activate_sim', // Activate SIM
  ACTIVATION_REQUESTED = 'activation_requested', // Activation requested
  AWAITING_ACTIVATION = 'activation_pending', // Awaiting activation
  ACTIVATION_FAILED = 'activation_failed', // Activation failed
}

export enum SignUpStatuses {
  SETUP = 'sign_up_setup', // Setup
  PENDING = 'sign_up_pending', // Invitation sent
  COMPLETED = 'sign_up_completed', // Invitation accepted
}

export enum MemberDashboardStatuses {
  PAUSE_SCHEDULED = 'pause_scheduled',
  ACTIVE = 'active',
}

export enum PostActivationStatuses {
  PAUSE_SCHEDULED = 'pause_scheduled',
  PAUSED = 'paused',
  ACTIVE = 'active',
  PLAN_CHANGE_PENDING = 'plan_change_pending', // Plan change pending
  EXPIRED = 'expired', // Expired
}

export enum StatusesAllowedForLinkToProfile {
  PAUSE_SCHEDULED = 'pause_scheduled',
  PAUSED = 'paused',
  ACTIVE = 'active',
}

export type Statuses =
  | ActivationStatuses
  | SignUpStatuses
  | PostActivationStatuses

export type Member = {
  customer_id: number
  customer_ref: string
  group_member_id: number
  is_owner: boolean
  is_member: boolean
  first_name: string
  last_name: string
  nickname: 'device' | 'under_16' | null
  email: string | null
  msisdn: string
  invitation_sent_at?: string
  member_sorting_order: number
  status: Statuses
}

export enum StatusColor {
  GREY = 'grey',
  GREEN = 'green',
  BLUE = 'blue',
  ORANGE = 'orange',
  YELLOW = 'yellow',
}

export type User = {
  id: number
  email: string
  password_digest: string
  created_at: string
  updated_at: string
  status: string
  failed_login_attempts: number
  magiclink_token: string | null
  magiclink_created_at: string | null
  reset_password_token: string | null
  reset_password_created_at: string | null
  reset_email_token: string | null
  reset_email_created_at: string | null
  registration_details_token: string
  registration_details_token_created_at: string
  lookup_id: string
  removed_at: null
}

export type Customer = {
  id: number
  user_id: number | null
  ref: number | null
  first_name: string
  last_name: string | null
  created_at: string
  updated_at: string
  marketing_acceptance_at: boolean
  third_party_acceptance_at: boolean
  dob: string | null
  braintree_customer_id: number | null
  monthly_payments_at: string | null
  store_payment_methods_at: string | null
  adult_content_at: string | null
  referrals_acceptance: boolean
  opted_out_of_transfer: boolean
  has_updated_network_settings: boolean
  sim_data_token: string | null
  termination_requested_at: string | null
  buyapowa_share_url: string | null
  show_account_credit: boolean | null
  sms_marketing_acceptance_at: string | null
  profiled_marketing_acceptance: boolean | null
  post_marketing_acceptance: boolean | null
  is_v2_owner: boolean | null
  push_notification_marketing_acceptance: boolean | null
  imi_customer_ref: string | null
  sign_up_via_group_status: string
  user: User | null
}

export type CustomerGroupMember = {
  id: number
  customer_id: number
  owner_id: number
  owner_accepted_terms_at: string
  member_accepted_terms_at: string
  member_rejected_invitation_at: string
  created_at: string
  updated_at: string
  owner_created_member_at: string
  disabled_at: string
  groups_implementation: number
  nickname: string
  owner_sent_invite_at: string
  member_requested_sim_activation_at: string
  member_sorting_order: number
  requested_plan_change_at: null
  customer: Customer
  invite: {
    group_member_id: number
    registration_details_token: string
    email: string
  }
}
