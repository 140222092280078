import React, { FC, useState } from 'react'

import { BulletPointItem, Icon, Typography } from '@smarty-nx/ds'
import cn from 'classnames'

import classes from './this-plan-includes.module.scss'
import { GroupPlanBanner } from '../../../../../components/GroupPlanBanner/group-plan-banner'
import {
  getPlanBenefits,
  whyJoinSmarty,
} from '../../../../../utils/planBenefits'
import { Plan } from '../../../../page-section/popular-plans/types'

declare module 'react' {
  interface CSSProperties {
    '--border-color'?: string
    '--checkmark-color-primary'?: string
    '--checkmark-color-secondary'?: string
  }
}

interface ThisPlanIncludesProps {
  selectedPlan: Plan
}

export const ThisPlanIncludes: FC<ThisPlanIncludesProps> = ({
  selectedPlan,
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const handleClick = () => setIsOpen(!isOpen)
  const content = getPlanBenefits(selectedPlan.planMeta, false)
  const columns = [
    {
      title: 'Why join SMARTY?',
      items: whyJoinSmarty,
      styles: undefined,
    },
    {
      title: 'Plan benefits',
      items: content.items,
      styles: {
        '--checkmark-color-primary': selectedPlan.planMeta.colorLight,
        '--checkmark-color-secondary': selectedPlan.planMeta.color,
      },
    },
  ]

  return (
    <div className={classes.root}>
      <div
        className={
          isOpen
            ? classes.heading
            : cn(classes.heading, classes.headingCollapsed)
        }
      >
        {content.title}
        <Icon
          onClick={handleClick}
          name={isOpen ? 'expanded-roundel' : 'collapsed-roundel'}
          className={classes.toggleRoundel}
        />
      </div>
      <div className={classes.bodyRoot}>
        <div
          className={
            isOpen ? classes.body : cn(classes.body, classes.bodyCollapsed)
          }
          style={{
            '--border-color': selectedPlan.planMeta.color,
          }}
        >
          <div className={classes.bodyContent}>
            {columns.map((column) => (
              <div key={column.title}>
                <Typography
                  variant="Kollektif/MediumEmphasis"
                  component="h3"
                  className={classes.columnTitle}
                >
                  {column.title}
                </Typography>
                <ul className={classes.items} style={column.styles}>
                  {column.items.map((item) => {
                    return (
                      <BulletPointItem
                        key={item.text}
                        text={item.text}
                        subText={item.subText}
                        as="li"
                      />
                    )
                  })}
                </ul>
              </div>
            ))}
          </div>
          <GroupPlanBanner />
        </div>
      </div>
    </div>
  )
}
