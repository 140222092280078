import React, { EventHandler, FC, ReactNode, useState } from 'react'

import { BulletPointItem, Button, Icon, Typography } from '@smarty-nx/ds'
import { Link } from 'gatsby'

import { Bubbles } from './bubbles'
import classes from './selected-plan.module.scss'
import { GroupPlanBanner } from '../../../../../components/GroupPlanBanner/group-plan-banner'
import {
  CHECKOUT_TYPES,
  TRACKING_CATEGORIES,
  TRACKING_STRINGS,
} from '../../../../../constants/globalStrings'
import { triggerProductEvent } from '../../../../../utils/dataLayer'
import { buildAssetsProductsProps } from '../../../../../utils/dmpgAttributes'
import {
  getPlanBenefits,
  whyJoinSmarty,
} from '../../../../../utils/planBenefits'
import { PlanCardCompact } from '../../../../components'
import { Plan } from '../../../../page-section/popular-plans/types'

declare module 'react' {
  interface CSSProperties {
    '--border-color'?: string
  }
}

interface CollapsibleProps {
  label: (cb: EventHandler<any>, isOpen: boolean) => ReactNode
  children: ReactNode
}

const Collapsible: FC<CollapsibleProps> = ({ children, label }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      {label(() => setOpen(!open), open)}
      <div className={open ? classes.expanded : classes.collapsed}>
        {children}
      </div>
    </>
  )
}

export const SelectedPlan: FC<{
  plan: Plan
  position: number
}> = ({ plan, position }) => {
  const hasVoucher = !!plan.priceData.voucher
  const content = getPlanBenefits(plan.planMeta, false)

  const columns = [
    {
      title: 'Why join SMARTY?',
      items: whyJoinSmarty,
      styles: undefined,
    },
    {
      title: 'Plan benefits',
      items: content.items,
      styles: {
        '--checkmark-color-primary': plan.planMeta.colorLight,
        '--checkmark-color-secondary': plan.planMeta.color,
      },
    },
  ]

  const tcChip =
    plan.priceData.voucher?.offer_chip_text || plan.priceData.tcChip
  const tcText =
    plan.priceData.voucher?.plan_card_heading || plan.priceData.tcText

  return (
    <div
      className={classes.root}
      style={{
        '--border-color': plan.planMeta.color,
      }}
    >
      {tcChip && <div className={classes.chip}>{tcChip}</div>}

      <div className={classes.plan}>
        {(tcChip || tcText) && (
          <Bubbles
            color={plan.planMeta.color}
            colorLight={plan.planMeta.colorLight}
          />
        )}
        <Link
          to={`/plans/${plan.planMeta.slug}`}
          className={classes.link}
          data-item-category={TRACKING_CATEGORIES.NAVIGATION}
          data-item-name={`carousel-${plan.planMeta.slug}`}
        >
          <PlanCardCompact
            position={position}
            planMeta={plan.planMeta}
            priceData={plan.priceData}
            isLabelled
          />
        </Link>
      </div>

      <div className={classes.extra}>
        <div className={classes.text}>
          {tcText || 'Great value data with unlimited calls and texts'}
        </div>
        <div className={classes.actions}>
          <Button
            data-testid={`${plan.planMeta.slug}-card-link`}
            onClick={() =>
              triggerProductEvent(
                TRACKING_STRINGS.ADD_TO_CART,
                buildAssetsProductsProps(plan).products[0],
                CHECKOUT_TYPES.REGULAR
              )
            }
            as={(props) => (
              <a
                {...props}
                rel="noopener noreferrer"
                href={`/order/${plan.planMeta.slug}`}
              >
                Buy plan
              </a>
            )}
          />
          {hasVoucher && (
            <Button
              level="tertiary"
              className={classes.tc}
              as={(props) => (
                <a
                  {...props}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={plan.priceData.voucher?.terms_and_conditions_url}
                >
                  Full offer T&C
                </a>
              )}
            />
          )}
        </div>
      </div>

      <Collapsible
        label={(onClick, isOpen) => (
          <div onClick={onClick} className={classes.details}>
            View plan details
            <Icon
              name="chevron"
              className={isOpen ? classes.iconOpen : classes.iconClosed}
            />
          </div>
        )}
      >
        {columns.map((column) => (
          <div key={column.title}>
            <Typography
              variant="Kollektif/MediumEmphasis"
              component="h3"
              className={classes.columnTitle}
            >
              {column.title}
            </Typography>
            <ul className={classes.items} style={column.styles}>
              {column.items.map((item) => {
                return (
                  <BulletPointItem
                    key={item.text}
                    text={item.text}
                    subText={item.subText}
                    as="li"
                  />
                )
              })}
            </ul>
          </div>
        ))}
        <GroupPlanBanner />
      </Collapsible>
    </div>
  )
}
