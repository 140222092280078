import {
  GET_PLANS,
  GET_PLANS_SUCCESS,
  GET_PLANS_FAILURE,
  SELECT_PLAN,
  PlansState,
} from './types'

const initialState: PlansState = {
  plans: [],
  isFetching: false,
  hasFetched: false,
  errorMessage: null,
  selectedPlan: null,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const plansReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_PLANS:
      return {
        ...state,
        isFetching: true,
        errorMessage: null,
      }
    case GET_PLANS_SUCCESS: {
      const {
        payload: { plans },
      } = action
      return {
        ...state,
        plans,
        isFetching: false,
        hasFetched: true,
        errorMessage: null,
      }
    }
    case GET_PLANS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload.errorMessage,
      }
    case SELECT_PLAN:
      return {
        ...state,
        selectedPlan: action.payload.planId,
      }
    default:
      return state
  }
}

export { initialState, plansReducer }
