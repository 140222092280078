export const createCookie = ({
  name,
  value,
  days = 0,
  domain = '',
  isSecure = false,
}: {
  name: string
  value: string
  days?: number
  domain?: string
  isSecure?: boolean
}) => {
  const domainStr = domain ? `; domain=${domain}` : domain
  const expires = days ? `; max-age=${days * 24 * 60 * 60}` : ''
  const secure = isSecure ? '; Secure' : ''

  document.cookie = `${name}=${value}${expires}${domainStr}${secure}; path=/`
}

export const getCookie = ({ name }: { name: string }) => {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(`${name}=`)
    if (c_start !== -1) {
      c_start = c_start + name.length + 1
      let c_end = document.cookie.indexOf(';', c_start)
      if (c_end === -1) {
        c_end = document.cookie.length
      }
      return decodeURI(document.cookie.substring(c_start, c_end))
    }
  }
  return null
}

export const removeCookie = ({
  name,
  domain = '',
}: {
  name: string
  domain?: string
}) => {
  if (document.cookie.length > 0) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;${
      domain && ` domain=${domain};`
    }`
  }
}

export default { getCookie, createCookie, removeCookie }
