import React, { FC } from 'react'

import cn from 'classnames'

import classes from './info-footer.module.scss'
import { Typography } from '../typography/typography'

interface InfoFooterProps {
  description?: string
  linkLabel?: string
  src?: string
  className?: string
}

export const InfoFooter: FC<InfoFooterProps> = ({
  description,
  linkLabel = 'Learn more',
  src,
  className,
}) => {
  return (
    <div className={cn(classes.root, className)}>
      {description && (
        <Typography
          className={classes.description}
          variant="Kollektif/ExtraSmall"
        >
          {description}
        </Typography>
      )}
      {linkLabel && src && (
        <a
          className={classes.cta}
          href={src}
          target="_blank"
          rel="noopener noreferrer"
        >
          {linkLabel}
        </a>
      )}
    </div>
  )
}
