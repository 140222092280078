import React, { FC } from 'react'

import { DATA_ITEM_CATEGORIES } from '@smarty-nx/code-utils'
import { getFooter } from '@smarty-nx/contentful'
import { Link as GatsbyLink } from 'gatsby-link'

import facebook from './facebook.svg'
import classes from './footer.module.scss'
import googlePlayStore from './google-play.svg'
import instagram from './instagram.svg'
import iosApp from './ios-app.svg'
import linkedin from './linkedin.svg'
import x from './x.svg'
import youtube from './youtube.svg'
import { Icon } from '../icon/icon'

/**
 * Design: https://www.figma.com/file/RE9n7PIwHG7A7WHA7kEVhN/WEB-Components?node-id=1002:126370&mode=dev
 * */

interface FooterData {
  footerGroups: FooterGroup[]
}

interface FooterGroup {
  title: string
  footerItems: FooterItem[]
}

interface FooterItem {
  href: string
  isRouterLink: boolean
  target: string
  testId: string
  text: string
  rel?: string
}

interface FooterProps {
  app: 'dashboard' | 'marketing'
  vatNumber: string
}

export const Footer: FC<FooterProps> = ({ app, vatNumber }) => {
  const isMarketingApp = app === 'marketing'
  const footerData: FooterData | null = getFooter(
    'dashboard',
    'Unauthenticated'
  )
  const footerGroups = footerData?.footerGroups || []

  return (
    <footer className={classes.root}>
      <div className={classes.inner}>
        <div className={classes.container}>
          <div className={classes.groupsWrapper}>
            {footerGroups.map((group) => (
              <ul key={group.title} className={classes.groupList}>
                <li className={classes.groupTitle}>{group.title}</li>
                {group.footerItems.map((footerItem) => {
                  const props = {
                    target: footerItem.target,
                    rel: footerItem.rel,
                    'data-testid': footerItem.testId,
                    'data-item-category': DATA_ITEM_CATEGORIES.NAVIGATION,
                    'data-item-name': footerItem.text,
                  }
                  return (
                    <li key={footerItem.testId}>
                      {footerItem.isRouterLink && isMarketingApp ? (
                        <GatsbyLink
                          to={footerItem.href}
                          className={classes.footerLink}
                          {...props}
                        >
                          {footerItem.text}
                        </GatsbyLink>
                      ) : (
                        <a
                          href={footerItem.href}
                          className={classes.footerLink}
                          {...props}
                        >
                          {footerItem.text}
                        </a>
                      )}
                    </li>
                  )
                })}
              </ul>
            ))}
          </div>
          <div className={classes.socials}>
            <div>
              <a href="https://smarty.co.uk" className={classes.logoLink}>
                <Icon name="smarty-logo" />
              </a>
              <div>
                <a
                  href="https://www.facebook.com/SMARTYMobileuk"
                  rel="noopener noreferrer"
                  target="_blank"
                  id="facebook"
                  data-testid="footer-facebook-icon"
                  className={classes.socialMediaLink}
                >
                  <img src={facebook} alt="facebook" />
                </a>
                <a
                  href="https://x.com/SMARTYMobileUK"
                  rel="noopener noreferrer"
                  target="_blank"
                  id="x"
                  data-testid="footer-x-icon"
                  className={classes.socialMediaLink}
                >
                  <img src={x} alt="x" />
                </a>
                <a
                  href="https://www.youtube.com/@SMARTYMobileUK"
                  rel="noopener noreferrer"
                  target="_blank"
                  id="youtube"
                  data-testid="footer-youtube-icon"
                  className={classes.socialMediaLink}
                >
                  <img src={youtube} alt="youtube" />
                </a>
                <a
                  href="https://www.linkedin.com/company/smartymobile-uk/"
                  rel="noopener noreferrer"
                  target="_blank"
                  id="linkedin"
                  data-testid="footer-linkedin-icon"
                  className={classes.socialMediaLink}
                >
                  <img src={linkedin} alt="linkedin" />
                </a>
                <a
                  href="https://www.instagram.com/smartymobileuk/"
                  rel="noopener noreferrer"
                  target="_blank"
                  id="instagram"
                  data-testid="footer-instagram-icon"
                  className={classes.socialMediaLink}
                >
                  <img src={instagram} alt="linkedin" />
                </a>
              </div>
            </div>
            <div>
              <a
                href="https://apps.apple.com/app/apple-store/id1540812668?pt=421745&mt=8&ct=smarty_web_download"
                rel="noopener noreferrer"
                target="_blank"
                aria-label="iOS App"
                data-testid="footer-ios-app-download-link"
                data-item-name="ios-app-download-link"
                data-item-category={DATA_ITEM_CATEGORIES.NAVIGATION}
                className={classes.appLink}
              >
                <img alt="Ios App" data-testid="ios-app" src={iosApp} />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.hutchison3g.sundae&referrer=utm_source%3Dsmarty%26utm_medium%3Dweb%26utm_content%3Dappstore_logo"
                rel="noopener noreferrer"
                target="_blank"
                aria-label="Google play store App"
                data-testid="footer-android-app-download-link"
                data-item-name="android-app-download-link"
                data-item-category={DATA_ITEM_CATEGORIES.NAVIGATION}
                className={classes.appLink}
              >
                <img
                  alt="Google Play Store App"
                  data-testid="google-play-store-app"
                  src={googlePlayStore}
                />
              </a>
            </div>
          </div>
        </div>
        <div className={classes.divider} />
        <p className={classes.footerInfo}>
          © 2024 All rights reserved. SMARTY.
          <br />
          <a href="https://smarty.co.uk">smarty.co.uk</a>
          <br />
          <br />
          SMARTY is a service that’s just for consumers and is only intended for
          personal use. SMARTY is a trading name of Hutchison 3G UK Limited
          <br />
          Registered office: Hutchison 3G UK Limited, 450 Longwater Avenue,
          Green Park, Reading, Berkshire, RG2 6GF. Registered in England & Wales
          <br />
          Number 03885486
          <br />
          VAT Registration Number {vatNumber}
        </p>
      </div>
    </footer>
  )
}
