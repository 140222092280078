import React, { FC } from 'react'

import { Icon, Typography } from '@smarty-nx/ds'
import cn from 'classnames'

import classes from './group-plan-banner.module.scss'
import TextLink from '../TextLink'

interface GroupPlanBannerProps {
  variation?: 'default' | 'allPlans'
}

export const GroupPlanBanner: FC<GroupPlanBannerProps> = ({
  variation = 'default',
}) => {
  return (
    <div className={cn(classes.root, classes[variation])}>
      <Icon name="groups-sims" className={classes.icon} />
      <div className={classes.content}>
        <Typography component="span" variant="Kollektif/ExtraSmallEmphasis">
          Save 10% on every SIM with a Group Plan.
        </Typography>
        <TextLink to="/group-plans" className={classes.link}>
          Learn more
        </TextLink>
      </div>
    </div>
  )
}
