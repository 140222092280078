/* eslint-disable @typescript-eslint/ban-ts-comment */

import { combineReducers, createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import { plansReducer as plans } from './plans/reducer'

/* eslint-disable no-underscore-dangle */
const composeEnhancers =
  (typeof window !== 'undefined' &&
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

const createRootReducer = () =>
  combineReducers({
    plans,
  })

/* eslint-disable no-underscore-dangle */
const dataStore = createStore(
  createRootReducer() /* preloadedState, */,

  composeEnhancers(applyMiddleware(thunk))
)
/* eslint-enable */

export { dataStore }
