export enum BackgroundTypes {
  Aquarium = 'aquarium',
}

export interface BillingLineItems {
  id: string
  label: string
  value: string
  add_background_highlight: boolean
}

export interface PlanGroup {
  groupDiscountPercentage: number | null
  groupDiscountValue: Price | null
}

export interface Referrals {
  referralCreditsUsed: boolean
  referralCreditsAvailable: number | null
  referralDiscount: string | null
}

export interface Offer {
  id: string
  tag: string
}

export interface Voucher {
  accordion_heading: string
  billing_line_items?: BillingLineItems[]
  code: string
  data_amount?: number
  discounted_price?: number
  hero_banner_id?: string
  is_default: boolean
  is_default_hero_banner?: boolean
  is_free_trial_voucher?: boolean
  multiple_offers?: Offer[]
  offer_chip_text: string
  offerChipText?: string
  offer_details: string
  plan_card_heading: string
  promoAddonCode?: string | null
  promoAddonDescription?: string | null
  promoAddonLimit?: number | null
  promoAddonUnit?: string | null
  promoDiscount?: Price | null
  promoMonthsRemaining?: number
  promoPercentageDiscount?: number | null
  promoType?: 'single' | 'multi'
  promoValidFrom?: string
  promoValidTo?: string
  roundel_type?: string
  show_countdown?: boolean
  terms_and_conditions_url: string
  title: string
  valid_from?: string
  valid_to?: string
  /**
   *  If set to `Yes`, this promotion can be used on accounts that are classified as either a Group Owner or Group Member.
   *  At the point of renewal - this flag will be checked to see if the renewal price should take into consideration this promotion.
   */
  isUsedWithGroups?: boolean
  /**
   *  This flag is used in conjunction with the Use with Groups Flag.
   *  If the promotion is set to use with groups, then this flag will allow the promotion to be combined with the 10% group discount.
   *  The promotional discount will be applied 1st, then any remaining price will be discounted by 10% group discount.
   */
  isCombinedWithGroups?: boolean
}

export interface PlanStyle {
  planColor: string
  planColorPale: string
}

export interface Plan {
  availableAccountBalance: Price | null
  availableDataDiscount: Price | null
  additionalSims?: Price | null
  categoryName?: string
  categoryType?: string
  dataAllowanceGB: number | null // unlimited plans have null
  dataAllowanceKB: number | null
  dataAllowancePromosOnlyGB?: number | null
  dataAllowanceWithPromosGB: number | null
  discountAmount?: number
  description: string
  expiry?: string | null
  finalPrice: Price
  finalPriceWithoutCredit: Price
  groups?: PlanGroup
  id: string
  name: string
  originalPrice: Price
  planHasDataDiscount: boolean
  planIsDataOnly?: boolean
  planIsSocialTariff: boolean
  planIsLegacy?: boolean
  planIsNew?: boolean
  referrals: Referrals
  remainingDataAllowanceGB: number | null
  remainingDataAllowanceKB: number | null
  remainingSmsAllowance: number | null
  remainingVoiceAllowance: number | null
  renewalDate: string
  slug: string
  smsAllowance: number | null
  style?: PlanStyle
  transactionId?: string | null
  usedAccountBalance: Price | null
  usedDataDiscount: Price | null
  voiceAllowance: number | null
  voucher: Voucher | null
  tcChip?: string
  tcText?: string
}

export interface Price {
  value: number
  currency: string
}

export interface MainTagPromo {
  planId?: string
  voucherCode: string
  bannerTitle: string
  bannerDescription: string
  ctaButtonText: string
  ctaButtonUrl: string
  backgroundType: BackgroundTypes
  backgroundHexCode: string
  imageUrl: string
  originalPrice: Price
  finalPrice: Price
  dataAllowanceGb: number
  dataAllowanceWithPromosGb: number
  dataAllowancePromosOnlyGb: number
  termsLink: string
  promoTag: string
}

export interface Promotion {
  mainTagPromo: MainTagPromo | null
  hasAnyPromos?: boolean
  currentOffers?: Plan[]
}

export interface DataAmount {
  symbol: string
  value: number
}

export type RestartPrices = {
  participants: {
    customerRef: string
    packagePrice: Price
  }[]
  prices: {
    total: Price
    balance: Price
    toPay: Price
    credit: Price
  }
}

export enum PLAN_ID {
  FAMILY_GROUP = 'SM_FG_UNLIMITED',
}
