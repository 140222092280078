import React, { ElementType, FC } from 'react'

import cn from 'classnames'

import classes from './bullet-point-item.module.scss'
import { ContentfulProcessor, Icon } from '../'

interface BulletPointItemProps {
  text: string
  subText?: string
  as?: ElementType
  className?: string
}

export const BulletPointItem: FC<BulletPointItemProps> = ({
  text,
  subText,
  as: Tag = 'div',
  className,
}) => {
  return (
    <Tag className={cn(classes.root, className)}>
      <Icon name="checkmark" className={classes.checkmark} />
      <span>{text}</span>
      {subText && (
        <>
          <span />
          <div className={classes.subText}>
            <ContentfulProcessor content={subText} />
          </div>
        </>
      )}
    </Tag>
  )
}
