import {
  ActivationStatuses,
  PostActivationStatuses,
  SignUpStatuses,
  StatusColor,
  Statuses,
} from '../types'
import { content } from './family.content'

/*
 * Family group summary status mapping
 */

export const statusColorMapping: {
  [key in Statuses]: {
    status: string
    color: StatusColor
  }
} = {
  [SignUpStatuses.SETUP]: {
    status: content.setup,
    color: StatusColor.GREEN,
  },
  [SignUpStatuses.PENDING]: {
    status: content.invitationSent,
    color: StatusColor.GREEN,
  },
  [SignUpStatuses.COMPLETED]: {
    status: content.invitationAccepted,
    color: StatusColor.GREEN,
  },
  [ActivationStatuses.ACTIVATION_REQUESTED]: {
    status: content.activationRequested,
    color: StatusColor.BLUE,
  },
  [ActivationStatuses.AWAITING_ACTIVATION]: {
    status: content.awaitingActivation,
    color: StatusColor.BLUE,
  },
  [ActivationStatuses.ACTIVATE_SIM]: {
    status: content.activateSim,
    color: StatusColor.GREY,
  },
  [ActivationStatuses.ACTIVATION_FAILED]: {
    status: content.activationFailed,
    color: StatusColor.ORANGE,
  },
  [PostActivationStatuses.ACTIVE]: {
    status: '', // We don't need to show this status label
    color: StatusColor.GREEN,
  },
  [PostActivationStatuses.PAUSE_SCHEDULED]: {
    status: content.pauseScheduled,
    color: StatusColor.YELLOW,
  },
  [PostActivationStatuses.PAUSED]: {
    status: content.paused,
    color: StatusColor.YELLOW,
  },
  [PostActivationStatuses.PLAN_CHANGE_PENDING]: {
    status: content.planChangePending,
    color: StatusColor.BLUE,
  },
  [PostActivationStatuses.EXPIRED]: {
    status: content.expired,
    color: StatusColor.YELLOW,
  },
}

export const statusLabels: { [key: string]: string } = Object.fromEntries(
  Object.entries(statusColorMapping).map(([k, o]) => [k, o?.status || ''])
)

export const getStatusAndColor = (
  activationState: Statuses | null
): [string | null, StatusColor | null] =>
  activationState && statusColorMapping[activationState]
    ? [
        statusColorMapping[activationState].status,
        statusColorMapping[activationState].color,
      ]
    : [null, null]
