import { PlanMeta } from './plans.types'

type PlanBenefitItem = {
  text: string
  subText?: string
}

type PlanBenefit = {
  title: string
  items: PlanBenefitItem[]
}

export enum PLAN_BENEFIT_TYPE {
  DEFAULT = 'default',
  DATA_ONLY = 'data-only',
  SOCIAL_TARIFF = 'social-tariff',
}

export const whyJoinSmarty: PlanBenefitItem[] = [
  {
    text: 'Cancel or change anytime',
  },
  {
    text: 'No annual price rises',
  },
  {
    text: 'No credit check',
  },
]

export const planBenefits: Record<PLAN_BENEFIT_TYPE, PlanBenefit> = {
  [PLAN_BENEFIT_TYPE.DEFAULT]: {
    title: 'All our plans include',
    items: [
      {
        text: 'Unlimited call and texts, WIFI calling',
      },
      {
        text: 'Fast 5G and 4G data',
      },
      {
        text: 'EU Roaming',
        subText: '12GB fair use limit',
      },
    ],
  },
  [PLAN_BENEFIT_TYPE.DATA_ONLY]: {
    title: 'Data Only plans include',
    items: [
      {
        text: 'Data only',
      },
      {
        text: 'Fast 5G and 4G data',
      },
      {
        text: 'EU Roaming',
        subText: '12GB fair use limit',
      },
    ],
  },
  [PLAN_BENEFIT_TYPE.SOCIAL_TARIFF]: {
    title: 'Social Tariff plan includes',
    items: [
      {
        text: 'Unlimited call and texts, WIFI calling',
      },
      {
        text: 'Fast 5G and 4G data',
      },
      {
        text: 'EU Roaming',
        subText: '12GB fair use limit',
      },
    ],
  },
}

export const getPlanBenefits = (
  planMeta?: PlanMeta,
  isSocialTariff = false
) => {
  if (isSocialTariff) {
    return planBenefits[PLAN_BENEFIT_TYPE.SOCIAL_TARIFF]
  }

  if (planMeta?.plan_is_data_only) {
    return planBenefits[PLAN_BENEFIT_TYPE.DATA_ONLY]
  }

  return planBenefits[PLAN_BENEFIT_TYPE.DEFAULT]
}
