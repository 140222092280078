export function isWindowExist(): boolean {
  return typeof window !== 'undefined'
}

export const safeLocation = () => {
  if (typeof window !== 'undefined') return window.location
  return {
    href: null,
    search: null,
    path: null,
    hash: null,
  }
}
