/**
 * TODO: Remove this file with folder once HORIZONS are live
 */
import React from 'react'

import { breakpoints, colors } from '@smarty-nx/ds'
import PropTypes from 'prop-types'
import { Parallax } from 'react-scroll-parallax'
import styled, { css } from 'styled-components'

import GroupHero from '../../components/GroupHero'
import GroupHowItWorks from '../../components/GroupHowItWorks'
import GroupOffer from '../../components/GroupOffer'
import GroupSteps from '../../components/GroupSteps'
import GroupWhy from '../../components/GroupWhy'
import PageHead from '../../components/PageHead'
import FullWidthContainer from '../../components/styles/FullWidthContainer'
import withApp from '../../components/withApp/withApp'
import groupCirclesLeft from '../../static/images/groupCirclesLeft.svg'
import groupCirclesRight from '../../static/images/groupCirclesRight.svg'

const DECORATION_BREAKPOINT = 1200

const customEllipse = css`
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  // IE 10 and up
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: none;
  }
  @media (min-width: ${breakpoints.desktop}px) {
    bottom: -6px;
  }
`

const customContainer = css`
  display: block;
  z-index: 0;
  overflow: hidden;
  position: relative;
`
const borderlessStyles = css`
  position: relative;
  margin-top: -2px;
`

const greenShape = css`
  &::after {
    content: '';
    top: 0;
    background: ${colors['Brand/PrimaryGreen']};
    height: 1000px;
    border-radius: 50%;
    position: absolute;
    right: -90%;
    width: 100%;
    z-index: -1;
  }
`
const seo = {
  title: 'Save 10% on every SIM with a Group plan (Best value SIM winner)',
  description:
    'Save an extra 10% on every SIM. Manage usage and payments for the whole group from a single place.',
}

const EllipseCutOut = ({ customStyle }) => (
  <svg
    viewBox="0 0 1240 206"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    css={customStyle}
  >
    <path
      d="M0 205.463V43.444c159.19 95.214 378.221 154.019 620 154.019s460.81-58.805 620-154.019v162.019H0z"
      fill="#fff"
    />
  </svg>
)

const Decoration = styled.img`
  width: 200px;
  height: 200px;
  position: absolute;
  top: 0;
  transform: ${({ left }) => left && 'rotateY(-180deg)'};
  ${({ left }) => (left ? 'left: -6%' : 'right: -6%')};
  @media (max-width: ${DECORATION_BREAKPOINT}px) {
    display: none;
  }
`

const GroupPlans = () => (
  <>
    <FullWidthContainer css={customContainer}>
      <EllipseCutOut customStyle={customEllipse} />
      <GroupHero />
    </FullWidthContainer>
    <FullWidthContainer
      background={colors['UI/White']}
      color={colors['Brand/PrimaryBlack']}
      css={borderlessStyles}
    >
      <Parallax x={[3, -3]}>
        <Decoration src={groupCirclesRight} alt="Circles decoration" />
      </Parallax>
      <GroupOffer />
    </FullWidthContainer>
    <FullWidthContainer
      background={colors['UI/White']}
      color={colors['Brand/PrimaryBlack']}
      css={borderlessStyles}
    >
      <Parallax x={[-3, 3]}>
        <Decoration left src={groupCirclesLeft} alt="Circles decoration" />
      </Parallax>
      <GroupWhy />
    </FullWidthContainer>
    <FullWidthContainer
      background={colors['UI/White']}
      color={colors['Brand/PrimaryBlack']}
      css={borderlessStyles}
    >
      <GroupSteps />
    </FullWidthContainer>
    <FullWidthContainer
      css={css`
        @media (min-width: ${breakpoints.desktop}px) {
          position: relative;
          overflow: hidden;
          ${greenShape};
        }
      `}
    >
      <GroupHowItWorks />
    </FullWidthContainer>
  </>
)

EllipseCutOut.propTypes = {
  customStyle: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default withApp(GroupPlans)

export const Head = () => (
  <PageHead title={seo.title} description={seo.description} />
)
