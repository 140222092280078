import React, { FC } from 'react'

import { BulletPointItem, Typography } from '@smarty-nx/ds'
import cn from 'classnames'

import classes from './AllPlans.module.scss'
import { GroupPlanBanner } from '../../components/GroupPlanBanner/group-plan-banner'
import FlyingSim from '../../static/images/flyingSimSmall.svg'
import { getPlanBenefits, whyJoinSmarty } from '../../utils/planBenefits'
import { PlanMeta } from '../../utils/plans.types'

interface AllPlansProps {
  headerTestId: string
  iconTestId: string
  listTestId: string
  variation: 'default' | 'socialTariff'
  currentPlan?: PlanMeta
  isSocialTariff?: boolean
  hasIcon?: boolean
}

const AllPlans: FC<AllPlansProps> = ({
  hasIcon,
  headerTestId,
  iconTestId,
  listTestId,
  currentPlan,
  variation = 'default',
}) => {
  const data = getPlanBenefits(currentPlan, variation === 'socialTariff')
  const columns = [
    {
      title: 'Why join SMARTY?',
      items: whyJoinSmarty,
    },
    {
      title: 'Plan benefits',
      items: data.items,
    },
  ]

  return (
    <div className={cn(classes.container, classes[variation])}>
      <div className={classes.header}>
        <Typography
          component="h2"
          data-testid={headerTestId}
          variant="Kollektif/ExtraLarge"
          className={classes.headerTitle}
        >
          {data.title}
        </Typography>
        {hasIcon ? (
          <img src={FlyingSim} alt="" className={classes.headerIcon} />
        ) : null}
      </div>
      <div className={classes.body}>
        <div className={classes.benefitColumns}>
          {columns.map((column) => (
            <div key={column.title}>
              <h3 className={classes.benefitColumnTitle}>{column.title}</h3>
              <div data-testid={listTestId} className={classes.itemsList}>
                {column.items.map((item) => (
                  <BulletPointItem
                    data-testid={iconTestId}
                    key={item.text}
                    text={item.text}
                    subText={item.subText}
                    className={classes.listItem}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
        <GroupPlanBanner variation="allPlans" />
      </div>
    </div>
  )
}

export default AllPlans
