import { createCookie } from './cookie'
import { safeLocation } from './safeWindow'

const naturalSearch = 'naturalSearch'
const organicSocialMedia = 'organicSocialMedia'

const getReferrer = (referrer: string) => {
  const urlObject = new URL(referrer)
  const hostname = urlObject.hostname || ''

  if (hostname.includes('google')) return naturalSearch
  if (hostname.includes('bing')) return naturalSearch
  if (hostname.includes('yahoo')) return naturalSearch
  if (hostname.includes('duckduckgo')) return naturalSearch
  if (hostname.includes('facebook')) return organicSocialMedia
  if (hostname.includes('twitter')) return organicSocialMedia
  if (hostname.includes('instagram')) return organicSocialMedia
  if (hostname.includes('youtube')) return organicSocialMedia
  return null
}

export const getAffiliate = ({
  url,
  referrer,
}: {
  url: string
  referrer?: string
}): string | null => {
  const newUrl = new URL(url)
  const searchParams = new URLSearchParams(newUrl.search)
  const gclid = searchParams.get('gclid')
  const utm_campaign = searchParams.get('utm_campaign')
  const utm_source = searchParams.get('utm_source')
  const utm_medium = searchParams.get('utm_medium')

  if (gclid) {
    return 'paidGenericSearch'
  } else if (utm_medium === 'paid') {
    return 'paidSocialMedia'
  } else if (utm_campaign === '895036311') {
    return 'paidSearchBrand'
  } else if (utm_medium === 'affiliates' && utm_campaign !== 'awin') {
    return 'affiliate'
  } else if (utm_medium === 'affiliates' && utm_campaign === 'awin') {
    return 'affiliateAwin'
  } else if (
    utm_source === 'adroll' ||
    (utm_campaign && utm_campaign.includes('TU')) ||
    (utm_campaign && utm_campaign.includes('r.ar1d.net'))
  ) {
    return 'adroll'
  } else if (referrer && getReferrer(referrer) === naturalSearch) {
    return naturalSearch
  } else if (referrer && getReferrer(referrer) === organicSocialMedia) {
    return organicSocialMedia
  }

  return null
}

/*
  This function is a temporary hack fix for broken QR codes.
  To be removed once QR codes are sorted.
 */
export const setQRRedirect = (searchParameters: string) => {
  const searchParams = new URLSearchParams(searchParameters)
  const campaign = searchParams.get('campaign')
  const utm_source = searchParams.get('utm_source')
  const utm_medium = searchParams.get('utm_medium')
  if (
    campaign === 'data_sim_pack' &&
    utm_source === 'data_sim' &&
    utm_medium === 'SIH'
  ) {
    safeLocation().href = '/activate'
  }
}

export const setAffiliateCookiesFromUrl = (searchString: string) => {
  const urlParams = new URLSearchParams(searchString)

  // AWIN:
  const awcTracking = urlParams.get('awc')
  if (awcTracking) {
    createCookie({ name: 'awc', value: awcTracking, days: 30 })
  }
}
