import React, { FC, useEffect, useState } from 'react'

import { getCookie } from '@smarty-nx/code-utils'
import { ContentfulProcessor, Modal, Button } from '@smarty-nx/ds'
import { Link as GatsbyLink } from 'gatsby'
import { ExitIntentHandler, useExitIntent } from 'use-exit-intent'

import classes from './exit-overlay.module.scss'
import { FEATURES } from '../../../config'
import { useIsAuthenticated } from '../../../hooks'
import {
  triggerModuleClickEvent,
  triggerModuleLoadEvent2,
} from '../../../utils/dataLayer'

declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface AnchorHTMLAttributes<T> {
    'data-item-id'?: string
    'data-item-type'?: string
  }
}

const USER_HAS_TO_BE_ON_A_PAGE_SECONDS = 5 * 1000

// copy-n-paste from comments, provided by DMPG team
const checkScrollSpeed = (function (settings: { delay?: number } = {}) {
  let timer: ReturnType<typeof setTimeout>
  let lastPos: number | null
  let newPos: number
  let delta: number
  const delay = settings.delay || 50 // in "ms" (higher means lower fidelity )

  function clear() {
    lastPos = null
    delta = 0
  }

  clear()

  return function () {
    newPos = window.scrollY
    if (lastPos !== null) {
      // && newPos < maxScroll
      delta = lastPos - newPos
    }
    lastPos = newPos
    clearTimeout(timer)
    timer = setTimeout(clear, delay)
    return delta
  }
})()

const moduleId = 'intent-to-exit'

const isTouchDevice = () => {
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    window.matchMedia('(pointer: coarse)').matches ||
    window.matchMedia('(hover: none)').matches
  )
}

const useTouchDevices = (
  callback: () => void,
  willBeTriggered: boolean,
  show: boolean
) => {
  const isTouch = isTouchDevice()

  useEffect(() => {
    if (!isTouch) {
      return
    }

    const handleScroll = () => {
      const scrollSpeed = checkScrollSpeed()

      if (scrollSpeed > 90) {
        callback()
      }
    }

    if (willBeTriggered && !show) {
      window.addEventListener('scroll', handleScroll)
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isTouch, callback, willBeTriggered, show])
}

const useDesktopDevices = (
  callback: () => void,
  registerHandler: (handler: ExitIntentHandler) => void
) => {
  const isTouch = isTouchDevice()

  useEffect(() => {
    if (isTouch) {
      return
    }

    const timer = setTimeout(() => {
      registerHandler({
        id: 'exit-intent-modal',
        handler: callback,
      })
    }, USER_HAS_TO_BE_ON_A_PAGE_SECONDS)

    return () => {
      clearTimeout(timer)
    }
  }, [isTouch, callback, registerHandler])
}

const Overlay: FC<{ content: Content }> = ({ content }) => {
  const [show, setShow] = useState(false)

  const showPopup = () => {
    setShow(true)

    setTimeout(() => {
      triggerModuleLoadEvent2(moduleId)
    }, 0)
  }

  const { registerHandler, unsubscribe, willBeTriggered } = useExitIntent({
    cookie: {
      key: 'smarty-exit-intent-modal',
      daysToExpire: 1 / 24, // 1 hour
    },
  })

  useTouchDevices(showPopup, willBeTriggered, show)
  useDesktopDevices(showPopup, registerHandler)

  return (
    <Modal
      data={{
        'module-id': moduleId,
        module_name: moduleId,
        module_placement: 'overlay',
      }}
      testId="exit-intent-modal"
      modalClassName={classes.modal}
      isOpen={show}
      onRequestClose={() => {
        setShow(false)
        unsubscribe()
      }}
      primaryCta={
        <ContentfulProcessor
          content={content.link || ''}
          components={{
            a({
              node: _,
              'data-item-id': id = 'unknown',
              'data-item-type': type = 'unknown',
              href,
              ...props
            }) {
              return (
                <Button
                  data-testid="exit-intent-cta"
                  onClick={() => {
                    triggerModuleClickEvent(id, type, moduleId)
                  }}
                  {...(props as Record<string, unknown>)}
                  as={(props) => <GatsbyLink {...props} to={href || ''} />}
                />
              )
            },
          }}
        />
      }
    >
      <div className={classes.root}>
        <div className={classes.img}>
          <img
            alt={content.image?.title || ''}
            src={content.image?.url || ''}
          />
        </div>
        <div className={classes.gap} />

        <div className={classes.heading}>{content.title}</div>
        <ContentfulProcessor
          content={content.description || ''}
          components={{
            h3({ node: _, ...props }) {
              return <div className={classes.subheading} {...props} />
            },
            p({ node: _, ...props }) {
              return <div className={classes.body} {...props} />
            },
          }}
        />
      </div>
    </Modal>
  )
}

export const ExitOverlay: FC<{ content: Content }> = ({ content }) => {
  const isAuthenticated = useIsAuthenticated()
  const affiliate = getCookie({ name: 'affiliate' })
  const AWIN = getCookie({ name: 'awc' })
  const isRaf = window.localStorage.getItem('referrer_customer_id')

  if (
    !FEATURES.EXIT_INTENT_OVERLAY ||
    isAuthenticated ||
    affiliate ||
    AWIN ||
    isRaf ||
    !content
  ) {
    return null
  }

  return <Overlay content={content} />
}

interface Content {
  title: string
  description: string
  link: string
  image: {
    title: string
    url: string
  }
}
