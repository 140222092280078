import { Plan } from '../types'

export const GET_PLANS = 'GET_PLANS'
export const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS'
export const GET_PLANS_FAILURE = 'GET_PLANS_FAILURE'
export const SELECT_PLAN = 'SELECT_PLAN'

export interface PlansState {
  plans: Plan[]
  isFetching: boolean
  hasFetched: boolean
  errorMessage: string | null
  selectedPlan: string | null
}
