import banners from '../../data/genericBanners.json'
import { genericBannerNames } from '../constants/contentfulTypes'

export interface GenericBannerItem {
  title: string
  type: string
  description?: string
  image?: {
    title: string
    url: string
  }
  link?: string
}

export const getBannerByType = (bannerType: string) => {
  if (typeof banners === 'undefined') {
    return null
  } else {
    return banners.find((item) => item.type === bannerType)
  }
}

export const getAskCommunityBanner = () =>
  getBannerByType(genericBannerNames.ASK_COMMUNITY)

export const getHelpCentreBanner = () =>
  getBannerByType(genericBannerNames.HELP_CENTRE)

export const getAppDownloadBanner = () =>
  getBannerByType(genericBannerNames.TRY_OUR_APP)

export const getChristmasOpeningHoursBanner = () =>
  getBannerByType(genericBannerNames.CHRISTMAS_OPENING_HOURS)

export const getPlanBenefitsBanner = () =>
  getBannerByType(genericBannerNames.PLAN_BENEFITS)

export const getPlanQuestionsBanner = () =>
  getBannerByType(genericBannerNames.FP_QUESTIONS)

export const getWhatYouCanDoBanner = () =>
  getBannerByType(genericBannerNames.FP_WHAT_YOU_CAN_DO)

export const getTheOwnerBanner = () =>
  getBannerByType(genericBannerNames.FP_THE_OWNER)

export const getTheMembersBanner = () =>
  getBannerByType(genericBannerNames.FP_THE_MEMBERS)

export const getWelcomeToGroupNotificationBanner = () =>
  getBannerByType(genericBannerNames.FP_WELCOME_TO_GROUP)

export const getWelcomeToSmartyNotificationBanner = () =>
  getBannerByType(genericBannerNames.FP_WELCOME_TO_SMARTY)

export const getInviteRejectedBanner = () =>
  getBannerByType(genericBannerNames.FP_INVITE_REJECTED)

export const getSoloPlansBanner = () =>
  getBannerByType(genericBannerNames.FP_SOLO_PLANS)

export const getInvitationSentBanner = (isResend = false) =>
  getBannerByType(
    isResend
      ? genericBannerNames.INVITATION_RESEND
      : genericBannerNames.INVITATION_SENT
  )

export const getRafPersonalisedBanner = () =>
  getBannerByType(genericBannerNames.RAF_BANNER)

export const getRaeBannerByReferralLink = () =>
  getBannerByType(genericBannerNames.RAE_BANNER_BY_REFERRAL_LINK)

export const getRaeBannerByBonusSim = () =>
  getBannerByType(genericBannerNames.RAE_BANNER_BY_BONUS_SIM)

export const getRaeBannerSendSimByPost = () =>
  getBannerByType(genericBannerNames.RAE_BANNER_SEND_BY_POST)

export const getRaeBannerFreeSimByPost = () =>
  getBannerByType(genericBannerNames.RAE_BANNER_A_FREE_SIM_BY_POST)

export const getInvalidInvitationBanner = (isAlreadyInGroup: boolean) =>
  isAlreadyInGroup
    ? getBannerByType(genericBannerNames.INVALID_INVITATION_IN_GROUP)
    : getBannerByType(genericBannerNames.INVALID_INVITATION_NOT_IN_GROUP)

export const getSwitchToSmartyBanner = () =>
  getBannerByType(genericBannerNames.SWITCH_TO_SMARTY)

export const getEveryoneUnlimited = () =>
  getBannerByType(genericBannerNames.EVERYONE_UNLIMITED)

export const getActivateYourSimFirst = () =>
  getBannerByType(genericBannerNames.ACTIVATE_YOUR_SIM_FIRST)

export const getRequestActivationBanner = () =>
  getBannerByType(genericBannerNames.FP_REQUEST_ACTIVATION)

export const getActivationFailedBanner = () =>
  getBannerByType(genericBannerNames.FP_ACTIVATION_FAILED)

export const getActivateNowBanner = ({
  isMember,
  isOwnerAsMember,
}: {
  isMember: boolean
  isOwnerAsMember: boolean
}) => {
  if (isOwnerAsMember) {
    return getBannerByType(genericBannerNames.FP_ACTIVATE_NOW_OWNER_AS_MEMBER)
  } else if (isMember) {
    return getBannerByType(genericBannerNames.FP_ACTIVATE_NOW_MEMBER)
  } else {
    return getBannerByType(genericBannerNames.FP_ACTIVATE_NOW_OWNER)
  }
}

export const getActivateNowMemberBanner = () =>
  getBannerByType(genericBannerNames.FP_ACTIVATE_NOW_MEMBER)

export const getActivationInProgressBanner = () =>
  getBannerByType(genericBannerNames.FP_ACTIVATION_IN_PROGRESS)

export const getSwitchingYourServicesWarningBanner = () =>
  getBannerByType(genericBannerNames.SWITCHING_YOUR_SERVICES_WARNING)

export const getWhatIsStacBanner = () =>
  getBannerByType(genericBannerNames.FP_WHAT_IS_STAC)

export const getWhatIsPacBanner = () =>
  getBannerByType(genericBannerNames.FP_WHAT_IS_PAC)

export const getGotYourDetailsWhatNextBanner = () =>
  getBannerByType(genericBannerNames.GOT_YOUR_DETAILS_WHAT_NEXT)

export const getInvalidAccountBanner = () =>
  getBannerByType(genericBannerNames.FP_INVALID_ACCOUNT)

export const getMemberInvitationSentBanner = () =>
  getBannerByType(genericBannerNames.FP_MEMBER_INVITATION_SENT)

export const getMemberInvitationAcceptedBanner = () =>
  getBannerByType(genericBannerNames.FP_MEMBER_INVITATION_ACCEPTED)

export const getActivationPendingBanner = () =>
  getBannerByType(genericBannerNames.FP_ACTIVATION_PENDING)

export const getMemberActivateSimBanner = (
  type: 'device' | 'under_16' | null
) =>
  type !== null
    ? getBannerByType(genericBannerNames.FP_DEVICE_ACTIVATE_SIM)
    : getBannerByType(genericBannerNames.FP_MEMBER_ACTIVATE_SIM)

export const getOwnerActivateSimBanner = () =>
  getBannerByType(genericBannerNames.FP_OWNER_ACTIVATE_SIM)

export const getAwaitingActivationBanner = () =>
  getBannerByType(genericBannerNames.FP_AWAITING_ACTIVATION)

export const getPlanChangePendingBanner = () =>
  getBannerByType(genericBannerNames.FP_PLAN_CHANGE_PENDING)

export const getActivationFailedMemberBanner = () =>
  getBannerByType(genericBannerNames.FP_ACTIVATION_FAILED_MEMBER)

export const getMemberAwaitingActivationBanner = () =>
  getBannerByType(genericBannerNames.FP_MEMBER_AWAITING_ACTIVATION)

export const getPleaseLogoutBanner = () =>
  getBannerByType(genericBannerNames.FP_PLEASE_LOGOUT)

export const getRejectInviteConfirmationBanner = () =>
  getBannerByType(genericBannerNames.FP_REJECT_INVITE)

export const getPortinPoolWarningBanner = () =>
  getBannerByType(genericBannerNames.FP_PORTIN_POOL_WARNING)

export const getPausedMemberPlanBanner = () =>
  getBannerByType(genericBannerNames.FP_PAUSED_MEMBER_PLAN)

export const getExpiredMemberPlanBanner = () =>
  getBannerByType(genericBannerNames.FP_EXPIRED_MEMBER_PLAN)

export const getPendingInvitationBanner = () =>
  getBannerByType(genericBannerNames.FP_PENDING_INVITATION)

export const getResumeYourPlanBanner = () =>
  getBannerByType(genericBannerNames.FP_RESUME_YOUR_PLAN_FIRST)

export const getReplacementSimBanner = () =>
  getBannerByType(genericBannerNames.REPLACEMENT_SIM)

export const getReplacementSimLimitBanner = () =>
  getBannerByType(genericBannerNames.REPLACEMENT_SIM_LIMIT)

export const getDeliveryAddressesLimitBanner = () =>
  getBannerByType(genericBannerNames.DELIVERY_ADDRESSES_LIMIT)

export const getRestartYourPlanBanner = () =>
  getBannerByType(genericBannerNames.FP_RESTART_YOUR_PLAN_FIRST)

export const getActivateSimFirstBanner = () =>
  getBannerByType(genericBannerNames.ACTIVATE_SIM_FIRST)

export const getRemoveMemberBanner = () =>
  getBannerByType(genericBannerNames.REMOVE_MEMBER)

export const getMemberRemovedBanner = () =>
  getBannerByType(genericBannerNames.MEMBER_REMOVED)

export const getRemoveMemberModalBanner = () =>
  getBannerByType(genericBannerNames.REMOVE_MEMBER_MODAL)
