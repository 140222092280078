import { Plan } from '../types'
import {
  GET_PLANS,
  GET_PLANS_FAILURE,
  GET_PLANS_SUCCESS,
  SELECT_PLAN,
} from './types'

export const getPlans = () => ({
  type: GET_PLANS,
})

export const getPlansSuccess = (plans: Plan[]) => ({
  type: GET_PLANS_SUCCESS,
  payload: { plans },
})

export const getPlansFailure = (errorMessage: string) => ({
  type: GET_PLANS_FAILURE,
  payload: {
    errorMessage,
  },
})

export const selectPlan = (planId: string) => ({
  type: SELECT_PLAN,
  payload: { planId },
})
