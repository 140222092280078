import { getPlans, getPlansSuccess, getPlansFailure } from './actions'

import { DispatchFunction } from '../types'
import api from '../api'

const doFetchPlans = () => async (dispatch: DispatchFunction) => {
  dispatch(getPlans())
  try {
    const result = await api.get('/plans')
    const { plans } = result?.data?.attributes || {}
    dispatch(getPlansSuccess(plans))
  } catch (error) {
    console.log(error)
    dispatch(getPlansFailure('Error fetching plans'))
  }
}

export { doFetchPlans }
