export const content = {
  activateSim: 'Activate SIM',
  active: 'Active',
  activationFailed: 'Activation failed',
  activationRequested: 'Activation requested',
  awaitingActivation: 'Awaiting activation',
  expired: 'Expired',
  invitationAccepted: 'Invitation accepted',
  invitationRejected: 'Invitation rejected',
  invitationSent: 'Invitation sent',
  pauseScheduled: 'Pause scheduled',
  paused: 'Paused',
  planChangePending: 'Plan change pending',
  setup: 'Setup',
  simDispatched: 'SIM dispatched',
}
