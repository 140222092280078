import React, { FC, Fragment } from 'react'

import ReactMarkdown from 'react-markdown'
import { Options } from 'react-markdown/lib/ast-to-react'
import rehypeRaw from 'rehype-raw'

interface ContentfulProcessorProps {
  content?: string
  components?: Options['components']
}

export const ContentfulProcessor: FC<ContentfulProcessorProps> = ({
  content = '',
  components,
}) => {
  return (
    <ReactMarkdown
      children={content}
      rehypePlugins={[rehypeRaw]}
      components={{
        p: Fragment, // removes default parent "paragraph" wrapper
        ...components,
      }}
    />
  )
}
